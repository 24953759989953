import React, { useState } from "react";
import { forgotPassowrd } from "../controllers/firebaseAuth";

const ForgotPassword = () => {
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [message, setMessage] = useState();
  return (
    <div className="py-5">
      <h1 className="text-2xl pb-3">Forgot Password?</h1>
      <input
        type="email"
        id="email"
        className="w-full px-3 py-3 border  focus:outline-none focus:ring-2 focus:ring-blue-600"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {error && <p className="text-red-500">{error}</p>}
      {message && <p className="text-green-500">{message}</p>}
      <button
        type="submit"
        onClick={() => {
          forgotPassowrd(
            email,
            (e) => {
              setMessage(e);
              setEmail("");
              setError(null);
            },
            setError
          );
        }}
        className="mt-4 w-full px-4 py-3 bg-black text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600"
      >
        Send Reset Email
      </button>
    </div>
  );
};

export default ForgotPassword;
