import React, { useState } from "react";
import googleLogo from "../images/google_mark.png";
import { emailLogin, googleSignIn } from "../controllers/firebaseAuth";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState();
  const [redirect, setRedirect] = useState();

  React.useEffect(() => {
    //get redirect url
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirect = urlParams.get("redirect");
    if (redirect) {
      setRedirect(redirect);
    }
  }, []);
  const handleLogin = async (e) => {
    if (!email) {
      setError("Please enter a email");
      return;
    }
    if (!password) {
      setError("Please enter a password");
      return;
    }

    e.preventDefault();
    emailLogin(
      email,
      password,
      redirect ? redirect : "https://www.blizzardpro.com",
      setError
    );
  };

  return (
    <div className="">
      <div className="w-full m-auto">
        <h2 className="text-2xl font-bold mb-4 font-condensed uppercase">
          Login
        </h2>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-left text-gray-700 font-bold mb-2"
            >
              Email Address
            </label>
            <input
              type="text"
              id="email"
              className="w-full px-3 py-3 border  focus:outline-none focus:ring-2 focus:ring-blue-600"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-left text-gray-700 font-bold mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="w-full px-3 py-3 border  focus:outline-none focus:ring-2 focus:ring-blue-600"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex w-full items-center justify-between flex-wrap">
            {error && <p className="text-red-600 pb-3">{error}</p>}
            <button
              type="submit"
              className="w-full px-4 py-3 bg-black text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600"
            >
              Login
            </button>
            {/* <button
              onClick={() => {
                googleSignIn(
                  setError,
                  redirect ? redirect : "https://www.blizzardpro.com"
                );
              }}
              type="button"
              className="w-full text-black focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium py-3 text-center border border-black inline-flex items-center dark:focus:ring-[#4285F4]/55 mt-3"
            >
              <div className="flex m-auto">
                <img
                  src={googleLogo}
                  alt="google logo"
                  className="h-6 w-6 mr-2"
                />
                Sign in with Google
              </div>
            </button> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
