import React, { useState } from "react";
import Login from "./components/Login";
import backgroundImg from "./images/login_bg.jpg";
import irisLogo from "./images/iris_mark.png";
import blizzLogo from "./images/blizzard_mark.png";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";
import axios from "axios";
import { auth } from "./firebase";
import { signInWithCustomToken } from "firebase/auth";

const App = () => {
  const [view, setView] = useState(0);
  const [rememberMe, setRememberMe] = useState(false);
  const [brand, setBrand] = useState();
  const [redirectUrl, setRedirectUrl] = useState();

  React.useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirect = urlParams.get("redirect");
    const token = urlParams.get("t");

    setBrand(brand);

    axios({
      method: "get",
      url: `https://api.blizzard.lighting/bear`,
      timeout: 10000,
      withCredentials: true,
    }).then((response) => {
      if (!response || !response.data || !response.data.bear) {
        console.log("no bear");
        return;
      }
    });
  }, []);

  const renderBranding = () => {
    if (brand === "iris") {
      return <img src={irisLogo} alt="IRiS Pro LED Video Wall" />;
    } else {
      return <img src={blizzLogo} alt="Blizzard Lighting" />;
    }
  };

  const renderSubNav = () => {
    if (view !== 2) {
      return (
        <div className="flex w-full pt-4 justify-between m-auto pb-12">
          <div className="login-form__field">
            <input
              type="checkbox"
              id="remember-me"
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
            />
            <label htmlFor="remember-me" className="pl-2">
              Remember Me
            </label>
          </div>
          <div>
            <button
              type="submit"
              className="underline"
              onClick={() => setView(2)}
            >
              Forgot Password?
            </button>
          </div>
        </div>
      );
    }
  };

  const renderView = () => {
    if (view === 0) {
      return <Login redirect={redirectUrl} />;
    } else if (view === 1) {
      return <SignUp redirect={redirectUrl} />;
    } else {
      return <ForgotPassword />;
    }
  };
  return (
    <div className="App">
      <div className="flex">
        <div style={{ width: "100%", maxWidth: 600 }}>
          <div className="relative top-10 left-10" style={{ width: 75 }}>
            {renderBranding()}
          </div>
          <div
            style={{ minHeight: 540 }}
            className="flex items-center justify-center h-screen flex-wrap "
          >
            <div style={{ width: "75%" }}>
              {renderView()}
              {renderSubNav()}
              {view === 0 ? (
                <div>
                  Don't have an account?
                  <span
                    className="pl-1 underline cursor-pointer"
                    onClick={() => setView(1)}
                  >
                    Sign Up
                  </span>
                </div>
              ) : (
                <div>
                  Already have an account?
                  <span
                    className="pl-1 underline cursor-pointer"
                    onClick={() => setView(0)}
                  >
                    Sign In
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="flex-grow movable-background"
          style={{
            backgroundImage: `url(${backgroundImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </div>
    </div>
  );
};

export default App;
