import axios from "axios";
const API_URL = "https://api.blizzard.lighting";

export const makeAxiosCall = (method, endpoint, data, config) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: method,
        url: API_URL + endpoint,
        data: data,
        timeout: 10000,
        withCredentials: true,
        ...config,
      });

      if (response) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      // console.error(`CAUGHT ERROR IN AXIOS ${method}`);
      // console.error(error.response ? error.response : error);
      reject(error);
    }
  });
};
