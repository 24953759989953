import React, { useState } from "react";
import { emailSignup } from "../controllers/firebaseAuth";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState();
  const [redirect, setRedirect] = useState("https://www.blizzardpro.com");

  React.useEffect(() => {
    //get redirect url
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirect = urlParams.get("redirect");
    if (redirect) {
      setRedirect(redirect);
    }
  }, []);

  const handleSignUp = (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("Please fill in all fields");
      return;
    }

    if (password.length < 8) {
      setError("Password must be at least 8 characters long");
      return;
    }

    if (password !== passwordConfirm) {
      setError("Passwords do not match");
      return;
    }

    emailSignup(firstName, lastName, email, password, redirect, setError);

    setError("");
  };

  return (
    <div className="">
      <div className="w-full m-auto">
        <h2 className="text-2xl font-bold mb-4 font-condensed uppercase">
          Sign Up
        </h2>
        <form onSubmit={handleSignUp}>
          <div className="mb-4 grid grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="firstName"
                className="block text-left text-gray-700 font-bold mb-2"
              >
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                autoComplete="given-name"
                className="w-full px-3 py-3 border  focus:outline-none focus:ring-2 focus:ring-blue-600"
                placeholder="Enter your First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="lastName"
                className="block text-left text-gray-700 font-bold mb-2"
              >
                Last Name
              </label>
              <input
                autoComplete="family-name"
                type="text"
                id="lastName"
                name="lastName"
                className="w-full px-3 py-3 border  focus:outline-none focus:ring-2 focus:ring-blue-600"
                placeholder="Enter your Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-left text-gray-700 font-bold mb-2"
            >
              Email
            </label>
            <input
              type="text"
              id="email"
              className="w-full px-3 py-3 border  focus:outline-none focus:ring-2 focus:ring-blue-600"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-left text-gray-700 font-bold mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="w-full px-3 py-3 border  focus:outline-none focus:ring-2 focus:ring-blue-600"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-left text-gray-700 font-bold mb-2"
            >
              Confirm Password
            </label>
            <input
              type="password"
              id="password"
              className="w-full px-3 py-3 border  focus:outline-none focus:ring-2 focus:ring-blue-600"
              placeholder="Enter your password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </div>
          <div className="flex w-full items-center justify-between flex-wrap">
            {error && <p className="text-red-600 pb-3">{error}</p>}
            <button
              type="submit"
              className="w-full px-4 py-3 bg-black text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600"
            >
              Sign Up
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
