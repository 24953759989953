import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase";
import axios from "axios";
import { makeAxiosCall } from "../utils";

const provider = new GoogleAuthProvider();

function formatFirebaseError(error) {
  console.log(error);
  if (!error) {
    return "Unknown error";
  }

  switch (error.code) {
    case "auth/email-already-in-use":
      return "Email already in use";
    case "auth/invalid-email":
      return "Invalid email";
    case "auth/wrong-password":
      return "Incorrect password";
    case "auth/user-not-found":
      return "User not found";
    case "auth/too-many-requests":
      return "Too many requests. Please try again later.";
    case "auth/network-request-failed":
      return "Network error. Please check your internet connection.";
    default:
      return error.message;
  }
}

export function forgotPassowrd(email, successCallback, errorCallback) {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      if (successCallback) {
        successCallback("Email sent! Check your inbox/spam folder.");
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(formatFirebaseError(error));
      }
    });
}

export const googleSignIn = (errorCallback, redirect) => {
  signInWithPopup(auth, provider)
    .then((result) => {
      //   const credential = GoogleAuthProvider.credentialFromResult(result);
      //   const token = credential.accessToken;
      const user = result.user;
      handleToken(user, redirect);
    })
    .catch((error) => {
      //   const errorCode = error.code;
      const errorMessage = error.message;
      //   const email = error.customData.email;
      //   const credential = GoogleAuthProvider.credentialFromError(error);
      errorCallback(errorMessage);
    });
};

function handleToken(user, redirect) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${user.accessToken}`;
  axios.get("https://api.blizzard.lighting/bear").then((res) => {
    const hasCartId = redirect.includes("?cartId=");
    let queryParams =
      `${hasCartId ? "&" : "?"}t=` + encodeURIComponent(user.accessToken);
    let tokenRedirect = redirect + queryParams;

    window.location.href = tokenRedirect;
  });
}

export const emailLogin = (email, password, redirect, errorCallback) => {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;

      handleToken(user, redirect);
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(formatFirebaseError(error));
      }
    });
};
export const emailSignup = (
  firstName,
  lastName,
  email,
  password,
  redirect,
  errorCallback
) => {
  createUserWithEmailAndPassword(auth, email, password, redirect, errorCallback)
    .then((userCredential) => {
      makeAxiosCall("post", "/create-web-customer", {
        id: userCredential.user.uid,
        email: email,
        firstName: firstName,
        lastName: lastName,
      })
        .then((res) => {
          handleToken(userCredential.user, redirect);
        })
        .catch((error) => {
          if (errorCallback) {
            errorCallback(error);
          }
        });
    })
    .catch((error) => {
      //   const errorMessage = error.message;
      if (errorCallback) {
        errorCallback(formatFirebaseError(error));
      }
    });
};
